import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ContactMain from '../../components/contactMain'
import { MDBAnimation } from 'mdbreact'

const Contact = ({ data, location }) => {
  const heroImage = data.heroImage

  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Contact Us"
            description="Want more information on UNICOM Digital products and services?  Please get in touch using the contact details or web form provided."
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}            
          />
          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            title="Need to get in touch?"
            subtitle="Fill out the form below and we'll get back to you"
            type="contact"
            alt="hand holding a paper plane ready to throw"
          />
        </div>

        <main>
          <section className="bg-gray-light">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <ContactMain />
            </MDBAnimation>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    heroImage: file(name: { eq: "contact-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }
  }
`
